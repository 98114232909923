import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'
import GraphImage from '../../images/assets/new-car-sales-graph.svg'

const rollercoasterPage = ({location}) =>
  (
    <Layout>

      <SEO title="The 2020 Australian car sales rollercoaster"
        description="2020 has been a COVID fuelled rollercoaster ride for every industry, and in particular car sales in Australia"
        location={location}
      />

      <PageHeader
        title="The 2020 Australian car sales rollercoaster"
        text="2020 has been a COVID fuelled rollercoaster ride for every industry, and in particular car sales in Australia"
      />

      <SectionPageMargin>

        <Heading2>New car sales</Heading2>

        <Paragraph1>
          2020 has been a COVID fuelled rollercoaster ride for every industry, and in particular car 
          sales in Australia.
        </Paragraph1>
        
        <Paragraph1>
          Last week the Federal Chamber of Automotive Industries (FCAI) revealed new car sales 
          figures for July, which unfortunately showed that the highs reached in June appear 
          to be a sugar-hit after restrictions eased.
        </Paragraph1>

        <Paragraph1>
          Looking back at the new car sales for the year, sales unsurprisingly slumped in 
          April and May as the reality of COVID sunk-in, businesses were forced to 
          close and lockdowns ratcheted up. 
        </Paragraph1>

        <Paragraph1>
          Confidence started to return in May, with buyers researching from home and 
          Carsales.com.au recorded their highest-ever month of traffic. 
        </Paragraph1>

        <ImageContainer>
          <GraphImage />
        </ImageContainer>

        <Paragraph1>
          And when we were allowed, blinking, back into the sunlight in June, people sprinted 
          to the dealerships. Some luxury car brands like BMW recorded <strong>all-time record</strong> 
          monthly new car sales. This was buoyed by End of Financial Year sales, and the extension 
          of the Instant Asset Write-off which applies to cars up to $150,000.
        </Paragraph1>

        <Paragraph1>
          However, in July the figures fell back to earth with less new cars sold than either March 
          or April, and August is predicted to be worse as Victoria (which contributes about 
          28% of new car sales) reached Stage 4 of lockdown and car dealerships and wholesalers 
          are forced to close. 
        </Paragraph1>
        
        <Paragraph1>
          The fall in new car sales is also due to stock shortages; with COVID causing delays in 
          production of cars (due to production line closures), and transportation of cars to Australia.
        </Paragraph1>

        <Paragraph1>
          Hopefully the greater restrictions have a big impact on the spread of COVID-19, and allow 
          for businesses to reopen, and Aussies to go car shopping again, which could lead to another 
          bounce in September. Hopefully the supply chain constraints have also eased, and there 
          are enough cars on our shores for people to buy. What is certain is that the rollercoaster 
          ride has some way to go yet, so hold on tight.
        </Paragraph1>

      </SectionPageMargin>

      <ReadyToGetStarted />

    </Layout>

  )

export default rollercoasterPage



const ImageContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;
